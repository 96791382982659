import React from 'react';
import { Button, CircularProgress, Backdrop, Stack, Box } from '@mui/material';
import GoogleSheetsApi from './GoogleSheetsApi'
import HabitsGSheet from './HabitsGSheet'
import './styles.css'

const credentials = {
	clientId: process.env.REACT_APP_GOOGLE_SHEETS_CLIENT_ID,
}

export default function App() {
  	return (
				<GoogleSheetsApi clientId={credentials.clientId}>
					{({ loading, signedIn, username, error, actionSignIn, actionSignOut }) => {
						console.log('SheetsDemo GoogleSheetsApi - loading', loading, 'signedIn', signedIn, 'error', error);
				
						if (loading) {
							return <Backdrop open={true}>
								<CircularProgress/>
							</Backdrop>

						} else if (error) {
							return(
								<>
								<p>Error loading Google API</p>
								<p>{JSON.stringify(error, null, 2)}</p>
								</>
							)
					
						} else if (signedIn) {
							return (
								<>
								<Stack alignItems="center">
									<Button variant="text" color="primary" onClick={actionSignOut}>
										{username ? username : 'no user'}
									</Button>
								</Stack>
								<HabitsGSheet/>
								</>
							);

						} else {
							return <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
								<Button variant="contained" color="primary" onClick={actionSignIn}>Sign in</Button>
							</Box>

						}
					}}
				</GoogleSheetsApi>
	);
}
