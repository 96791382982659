import React from 'react';
import { Paper, Tooltip } from '@mui/material';
import DialogMultipleChoice from './DialogMultipleChoice';


const HabitItem = props => {
    const [openMultiChoice, setOpenMultiChoice] = React.useState(false);

    const onClickItem = () => {
		console.log('HabitItem - click', props.habit.icon);

        if (icons.length === 1) {
            selectIcon(props.habit.icon);
        } else {
            setOpenMultiChoice(true);
        }
	}

    const selectIcon = (value) => {
        console.log('HabitItem - selectIcon', props.habit.icon, ' -> ', value);
        onCloseMultiChoice();
        props.reportBackClick(props.habit.icon, value);
    }

    const onCloseMultiChoice = () => {
        setOpenMultiChoice(false);
    };
	
    const icons = [...new Intl.Segmenter().segment(props.habit.icon)].map(e => e.segment);
    const lastValues = props.habit.lastValues;
	// console.log('HabitItem', props.habit.value, props.habit.icon, icons);

    return (
        <>
        <Tooltip title={props.habit.description} placement='top'>
            <Paper
                onClick={onClickItem}
                sx={{ fontSize: 48, textAlign: 'center', p: 1, 
                    backgroundColor: props.habit.value  ? 'success.main' : '' }}
                >
                    {icons.length === 1 
                    ? props.habit.icon 
                    : props.habit.value 
                    ? props.habit.value 
                    : icons[0]
                    }
            </Paper>
        </Tooltip>
        <DialogMultipleChoice
            openMultiChoice={openMultiChoice}
            handleClose={onCloseMultiChoice}
            selectIcon={selectIcon}
            icons={lastValues}
            selectedIcon={props.habit.value}
            />
        </>
    );

}

export default HabitItem;