import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider, CssBaseline, Container, Box } from '@mui/material';
import theme from './theme';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  	<StyledEngineProvider injectFirst>
		<ThemeProvider theme={theme}>
			<CssBaseline/>
			<Container maxWidth="sm">
				<Box sx={{ my: 4 }}>
					<App />
				</Box>
			</Container>
		</ThemeProvider>
	</StyledEngineProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
