import React from 'react';
import { Stack, Typography, Dialog, DialogContent } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import DialogNewValue from './DialogNewValue'


const DialogMultipleChoice = props => {
    const { openMultiChoice, handleClose, selectIcon, icons, selectedIcon } = props;

    const [openAddNew, setOpenAddNew] = React.useState(false);

    const openDialogToAddNewValue = () => {
        // setOpenMultiChoice(false);
        setOpenAddNew(true);
        // textFieldRef.current = selectedIcon;
    }

    const addNewValue = (value) => {
        console.log('addNewValue', value);
        setOpenAddNew(false);
        if (value) {
            selectIcon(value);
        }
        // props.reportBackClick(props.habit.icon, );
    };
	
    return (
        <>
        <Dialog onClose={handleClose} open={openMultiChoice}>
           <DialogContent>
               <Stack direction="row" sx={{ flexWrap: 'wrap' }} justifyContent="center">
                   {icons.map((icon, i) => 
                       <Typography 
                           onClick={() => selectIcon(icon)}
                           fontSize={48} borderRadius={3} padding={1}
                           backgroundColor={icon === selectedIcon ? 'success.main' : ''}
                           key={i}
                           >
                               {icon}
                       </Typography>
                   )}
                   <Typography 
                       onClick={openDialogToAddNewValue}
                       fontSize={48} borderRadius={3} padding={1}
                       key={-1}
                       >
                           <MoreHoriz/>
                   </Typography>
               </Stack>
           </DialogContent>
        </Dialog> 
        <DialogNewValue
            openAddNew={openAddNew}
            addNewValue={addNewValue}
            currentValue={selectedIcon}
            />
        </>
    )

}



export default DialogMultipleChoice;