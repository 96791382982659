import React from 'react'
//import { GoogleApi } from '@lourd/react-google-api'
import { GoogleApi } from './GoogleApi'

const GoogleSheetsApi = ({
  scopes = [
    //'https://www.googleapis.com/auth/spreadsheets.readonly',
    'https://www.googleapis.com/auth/spreadsheets'
  ],
  ...props
}) => (
  <GoogleApi
    scopes={scopes}
    discoveryDocs={['https://sheets.googleapis.com/$discovery/rest?version=v4']}
    {...props}
  />
)

export default GoogleSheetsApi
