import { cyan, pink } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: cyan[800],
		},
		secondary: pink,
		mode: 'dark',
	},
});

export default theme;