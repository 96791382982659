import React from 'react'
import PropTypes from 'prop-types'
import equalByKeys from '@lourd/equal-by-keys'
//import { GoogleApiConsumer } from '@lourd/react-google-api'
import { GoogleApiConsumer } from './GoogleApi'

class GSheetData extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    range: PropTypes.string.isRequired,
    api: PropTypes.object.isRequired,
  }

  state = {
    error: null,
    data: null,
    loading: false,
    googleApi: null
  }

  constructor(props) {
    super(props)
    this.fetch = this.fetch.bind(this)
  }

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps) {
    if (!equalByKeys(this.props, prevProps, 'id', 'range')) {
      this.fetch()
    }
  }

  async fetch() {
    // step 1 - mark loading
    this.setState({ loading: true })
    try {
      const params = {
        spreadsheetId: this.props.id,
        range: this.props.range,
      }

      console.log('GSheetData 1', this.props.api);
      // step 2 - request values from api
      const response = await this.props.api.client.sheets.spreadsheets.values.get(
        params,
      )
      // Unable to cancel requests, so we wait until it's done and check it's still the desired one
      if (
        this.props.id === params.spreadsheetId &&
        this.props.range === params.range
      ) {

        console.log('GSheetData 2', this.props.api, 'response', response);
          // step 3 - set successfull result
        this.setState({
          loading: false,
          error: null,
          data: response.result.values,
          googleApi: this.props.api
        })
      }
    } catch (response) {
      // If the api is still null, this will be a TypeError, not a response object

      // step 4 - set fail result
      const error = response.result ? response.result.error : response
      this.setState({ loading: false, error })
    }
  }

  render() {
    return this.props.children({
      error: this.state.error,
      data: this.state.data,
      loading: this.state.loading,
      googleApi: this.state.googleApi,
      refetch: this.fetch,
    })
  }
}

const GoogleSheet = props => (
  <GoogleApiConsumer>
    {gApi => <GSheetData api={gApi} {...props} />}
  </GoogleApiConsumer>
)

export default GoogleSheet
export { GSheetData }
