import React from 'react';
import { HabitsList, normalizeDate } from './HabitsList'
import GoogleSheet from './GoogleSheet'
import { CircularProgress, Stack } from '@mui/material';

const HABITS_SPREADSHEET_ID = process.env.REACT_APP_HABITS_SPREADSHEET_ID;
const RANGE_TO_READ = 'log!1:12';

function transpose(rows) {
    return rows[0].map(
        (_, colIndex) => rows.map(row => row[colIndex])
    );        
}


const HabitsGSheet = props => {


    const convertData = rows => {
        // the first n rows 
        // line 1 - title 
        // line 2 - icons
        // further lines - values per date
        // column 1 - dates
        // column 2 - comment
        // further columns - values per date

        console.log('SimpleGSheet rows - ', rows);
        const lastValues = transpose(rows.slice(1))
            .slice(2).map(cell => {
                const allValues = cell
                    .slice(1)
                    .filter(e => e && e !== '');
                const uniqueValues =  [...new Set(allValues)]
                    .sort();
                return {
                    icon: cell[0],
                    values: uniqueValues,
                }
            });
        console.log('SimpleGSheet columns', lastValues);
        // cells - description, icon, value
        const titles = transpose(rows.slice(0, 2))
            .slice(2)
            .map(cell => {
                const icon = cell[1];
                const lv = lastValues.find(e => e.icon === icon);

                return {
                    icon: icon, 
                    description: cell[0],
                    lastValues: lv ? lv.values : [],
                }
            });
        console.log('SimpleGSheet titles', titles);

        const log = rows.slice(2).map(row => {
            const curDate = row[0];
            // column 1 - comment
            const rowData = row.slice(2);
            const mappedData = titles.map((title, i) => { return {
                ...title, 
                value: rowData[i] && rowData[i].length > 0 ? rowData[i] : undefined,
            }});

            console.log('SimpleGSheet row', row, 'curDate', curDate, 'rowData', rowData, 'mappedData', mappedData);

            return {
                date: normalizeDate(new Date(curDate)),
                data: mappedData,
            };            
        });
        console.log('SimpleGSheet habits', log);
        const habits = {
            titles: titles,
            log: log,
        };

        return habits;
    }

    return (
    <GoogleSheet id={HABITS_SPREADSHEET_ID} range={RANGE_TO_READ}>
        {({ error, data, loading, googleApi }) => {
    
            console.log('SimpleGSheet - error', error, 'data', data, 'loading', loading, 'api', googleApi);
    
            if (loading) {
                return <Stack alignItems="center">
                    <CircularProgress sx={{ m: 4 }}/>
                </Stack>
    
            } else if (error) {
                return <pre>{JSON.stringify(error, null, 2)}</pre>
    
            } else if (data) {
                
                const habits = convertData(data);
        
                return (
                <HabitsList 
                    habits={habits} 
                    googleSheetsClient={googleApi.client}
                    />
                );
        
            } else {
                return null
            }
        }}
    </GoogleSheet>
    )
}
  
export default HabitsGSheet;
  