/* global gapi */
import React from 'react'
import PropTypes from 'prop-types'
import { createContext } from 'react-broadcast'
import loadScript from '@lourd/load-script'

const {
  Provider: GoogleApiProvider,
  Consumer: GoogleApiConsumer,
} = createContext(null)
GoogleApiProvider.displayName = 'GoogleApiProvider'
GoogleApiConsumer.displayName = 'GoogleApiConsumer'

class GoogleApi extends React.Component {
  static propTypes = {
    clientId: PropTypes.string.isRequired,
    discoveryDocs: PropTypes.arrayOf(PropTypes.string).isRequired,
    scopes: PropTypes.arrayOf(PropTypes.string).isRequired,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  }

  actionSignIn = () => {
    if (this.auth) {
      this.auth.signIn()
    }
  }

  actionSignOut = () => {
    if (this.auth) {
      this.auth.signOut()
    }
  }

  state = {
    loading: true,
    signedIn: false,
    client: null,
    username: null,
    error: null,
    actionSignIn: this.actionSignIn,
    actionSignOut: this.actionSignOut,
  }

  componentDidMount() {
    this.setupApi()
  }

  async setupApi() {
    // step 1 - init: load script, load auth2, call init
    try {
      if (typeof window.gapi === 'undefined') {
        await loadScript('https://apis.google.com/js/api.js')
      }
      if (!gapi.client) {
        await new Promise((resolve, reject) =>
          gapi.load('client', {
            callback: resolve,
            onerror: reject,
          }),
        )
      }
      await gapi.client.init({
        clientId: this.props.clientId,
        discoveryDocs: this.props.discoveryDocs,
        scope: this.props.scopes.join(','),
      })
    } catch (error) {
      this.setState({
        loading: false,
        error,
      })
      return
    }
    console.log('setupApi 2', gapi.client);

    // step 2 - check authentification
    this.auth = gapi.auth2.getAuthInstance()

    let signedIn = this.auth.isSignedIn.get();
    console.log('setupApi isSignedIn', signedIn);
    if (signedIn) 
      try {
        console.log('reloadAuthResponse', await this.auth.currentUser.get().reloadAuthResponse());      
      } catch (error) {
        console.error('error on reloadAuthResponse', error);
      }

    this.setState({
      loading: false,
      signedIn: this.auth.isSignedIn.get(),
      username: this.getUserEmail(),
      client: gapi.client,
    });

    // console.log('GoogleApi state', this.state);
    this.auth.isSignedIn.listen(async (signedIn) =>  {
      this.setState({ 
        signedIn: signedIn,
        username: signedIn ? this.getUserEmail() : null,
      });
    })
  }

  getUserEmail() {
    if (this.auth.currentUser.get().getBasicProfile())
      // console.log('currentUser', this.auth.currentUser.get().getBasicProfile().getEmail());
      return this.auth.currentUser.get().getBasicProfile().getEmail();
    else
      // console.log('currentUser - not logged on');
      return null;
  }

  render() {
    return (
      <GoogleApiProvider value={this.state}>
        {typeof this.props.children === 'function'
          ? this.props.children(this.state)
          : this.props.children}
      </GoogleApiProvider>
    )
  }
}

export { GoogleApi, GoogleApiConsumer, GoogleApiProvider }
