import React from 'react';
import { Grid, Button, Stack, Typography } from '@mui/material';
import HabitItem from './HabitItem'
import A1 from '@flighter/a1-notation';

const HABITS_SPREADSHEET_ID = process.env.REACT_APP_HABITS_SPREADSHEET_ID;
const HABITS_SHEET_ID = process.env.REACT_APP_HABITS_SHEET_ID;

async function updateValue(value, row, column, client) {
	console.log('updateValue input', value, row, column);
    var values = [[ value ]];

    try {
        // https://developers.google.com/sheets/api/guides/values
        const response = await client.sheets.spreadsheets.values.update({
            spreadsheetId: HABITS_SPREADSHEET_ID,
            range: 'log!' + new A1(column + 1, row + 1),
            valueInputOption: 'USER_ENTERED',
            resource: {  values },
        });

        console.log('updateValue response', response);
    } catch (response) {
        const error = response.result ? response.result.error : response
        console.error('updateValue error', error);
    }

}
  
async function insertNewLine(row, client) {
    try {
        // https://developers.google.com/sheets/api/reference/rest/v4/spreadsheets/request#insertdimensionrequest
        // https://developers.google.com/sheets/api/reference/rest/v4/spreadsheets/batchUpdate
        const response = await client.sheets.spreadsheets.batchUpdate({
            spreadsheetId: HABITS_SPREADSHEET_ID,
            resource: {
                requests: [{
					insertDimension: {  
						range: {
							sheetId: HABITS_SHEET_ID,
							dimension: "ROWS",
							startIndex: row,
							endIndex: (row + 1),
						}
					}
                }],
                includeSpreadsheetInResponse: false,
            }
		});
        
        console.log('insertNewLine response', response);
    } catch (response) {
        const error = response.result ? response.result.error : response
        console.error('insertNewLine error', error);
    }
}

function normalizeDate(date) {
	return new Date(date.toDateString());
}

function getToday() {
    return normalizeDate(new Date());
}

function addDays(date, days) {
	return normalizeDate(new Date(date.getTime() + days * 24 * 60 * 60 * 1000));
}

function getISODate(date) {
	return new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
		.toISOString()
		.split("T")[0]
}
  

class HabitsList extends React.Component {
	constructor(props) {
		super(props);
		this.reportBackClick = this.reportBackClick.bind(this);
		this.clickPrevDay = this.clickPrevDay.bind(this);
		this.clickNextDay = this.clickNextDay.bind(this);
		this.switchDay = this.switchDay.bind(this);
		this.getIntervalDates = this.getIntervalDates.bind(this);
		
		const [_, newDate, disablePrev, disableNext] = this.getIntervalDates(null, 0);

		console.log('HabitsList - initial habits', newDate.toDateString(), this.props.habits);
		// habits - array
		// - date
		// - data - array
		//   - icon
		//   - description
		//   - value
		this.state = { 
			habits: this.props.habits,
			curDate: newDate,
			disablePrev: disablePrev,
			disableNext: disableNext,
		};
	}

	reportBackClick(icon, requestedValue) {
		const logIndex = this.state.habits.log.findIndex(e => +this.state.curDate === +e.date);
		const dateMissing = logIndex < 0;

		console.log('HabitsList - reportBackClick - start', icon, requestedValue, this.state.curDate, logIndex);
		const newHabits = Object.assign({}, this.state.habits);
		const curHabits = dateMissing
			? newHabits.titles.slice()
			: newHabits.log[logIndex].data;

		const habit = curHabits.find(e => e.icon === icon);
		const habitIndex = curHabits.findIndex(e => e.icon === icon);
		const newValue = habit.value && habit.value === requestedValue? undefined : requestedValue;
		habit.value = newValue;
		if (dateMissing) {
			newHabits.log.push({
				date: normalizeDate(new Date(this.state.curDate)),
                data: curHabits,
			});
			newHabits.log.sort((a, b) => (a.date < b.date ? 1 : -1));
		}
		const newHabitsLogIndex = newHabits.log.findIndex(e => +this.state.curDate === +e.date);
		console.log('HabitsList - reportBackClick - adjusted', newHabits);
			
		this.setState({ 
			habits: newHabits,
		});

		new Promise(async () => {
			if (dateMissing) {
				await insertNewLine(
					newHabitsLogIndex + 2, 
					this.props.googleSheetsClient,
				);
				await updateValue(
					getISODate(this.state.curDate),
					newHabitsLogIndex + 2, // the first row - 0
					0, // column A - 0
					this.props.googleSheetsClient,
				);
			} 
		
			await updateValue(
				newValue ?? '',
				newHabitsLogIndex + 2,
				habitIndex + 2, // the first column is for dates, the second column is empty
				this.props.googleSheetsClient,
			); 
		});
	}

	getIntervalDates(curDate, days) {
		// allow to select any day between today and -10 days
		const today = getToday();
		const firstDate = addDays(today, -10);
		const newDate = addDays(curDate ?? today, days);
		const disablePrev = +firstDate >= +newDate;
		const disableNext = +newDate >= +today;
		console.log('switchDay', curDate, days, newDate.toDateString(), firstDate.toDateString(), +firstDate <= +newDate, today.toDateString(), +newDate <= +today);
		if (+firstDate <= +newDate && +newDate <= +today) {
			return [true, newDate, disablePrev, disableNext];
		} else {
			return [false, newDate, disablePrev, disableNext];
		}
	}

	switchDay(days) {
		const [proceed, newDate, disablePrev, disableNext] = this.getIntervalDates(this.state.curDate, days);
		if (proceed) {
			this.setState({ 
				curDate: newDate,
				disablePrev: disablePrev,
				disableNext: disableNext,
			});
		}
	}

	clickPrevDay() {
		this.switchDay(-1);
	}

	clickNextDay() {
		this.switchDay(1);
	}

	render() { 
		const logHabits = this.props.habits.log.find(e => +this.state.curDate === +e.date);

		const curHabits = logHabits 
			? logHabits.data.slice()
			: this.props.habits.titles.map(e => { return {...e, value: undefined}});

		console.log('HabitsList - logHabits', logHabits, 'curHabits', curHabits);

		return ( <>
		<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx = {{ padding: 2 }}>
			<Button variant="text" onClick={this.clickPrevDay} disabled={this.state.disablePrev}>Prev</Button>
			<Typography>{this.state.curDate.toDateString()}</Typography>
			<Button variant="text" onClick={this.clickNextDay} disabled={this.state.disableNext}>Next</Button>
		</Stack>
		<Grid container spacing={3}>
			{curHabits.map((habit, i) =>
				<Grid item key={i} xs={4}>
					<HabitItem 
						habit={habit}
						reportBackClick={this.reportBackClick}
						/>
				</Grid>
			)}
		</Grid>
	</> )}
}

export { HabitsList, normalizeDate };
