import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogContentText, TextField, DialogActions, Button } from '@mui/material';

const DialogNewValue = props => {
    const { openAddNew, addNewValue, currentValue } = props;

    const textFieldRef = React.useRef();

    const handleClose = () => {
        addNewValue();
    };

    const useNewValue = () => {
        // addNewValue(newValue);
        console.log('textFieldRef.current.value', textFieldRef.current.value);
        addNewValue(textFieldRef.current.value);
    };
	
    return (
        <Dialog open={openAddNew} onClose={handleClose}>
            <DialogTitle>Add new value</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Add new value
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="New value"
                    fullWidth
                    variant="standard"
                    defaultValue={currentValue}
                    inputRef={textFieldRef}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Back</Button>
                <Button onClick={useNewValue}>Add</Button>
            </DialogActions>
        </Dialog>
    )

}

export default DialogNewValue;